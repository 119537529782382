// HomePage.js

import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FitsContext } from '../contexts/FitsContext';
import {
  Card, CardContent, Typography, TextField, Button, MenuItem, Container, CircularProgress, Box
} from '@mui/material';
import {
  FacebookShareButton, WhatsappShareButton, TwitterShareButton, EmailShareButton,
  FacebookIcon, FacebookMessengerIcon, WhatsappIcon, TwitterIcon, EmailIcon
} from 'react-share';
import LinkIcon from '@mui/icons-material/Link';
import './HomePage.css';
import useMediaQuery from '@mui/material/useMediaQuery';

const HomePage = () => {
  const { submissions, incrementSuggestionCount, incrementItemsSuggestedCount } = useContext(FitsContext);
  const [age, setAge] = useState('');
  const [gender, setGender] = useState('');
  const [temperature, setTemperature] = useState('');
  const [dressCode, setDressCode] = useState('');
  const [clothingType, setClothingType] = useState('');
  const [initialFilteredSubmissions, setInitialFilteredSubmissions] = useState([]);
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState('');
  const [showError, setShowError] = useState(false);
  const isLargeScreen = useMediaQuery('(min-width:1264px)');

  useEffect(() => {
    const storedState = localStorage.getItem('filterState');
    if (storedState) {
      const { age, gender, temperature, dressCode, filteredSubmissions } = JSON.parse(storedState);
      setAge(age);
      setGender(gender);
      setTemperature(temperature);
      setDressCode(dressCode);
      setFilteredSubmissions(filteredSubmissions);
      setInitialFilteredSubmissions(filteredSubmissions);
    }
  }, []);

  useEffect(() => {
    if (submissions.length > 0) {
      console.log('Submissions:', submissions);
    }
  }, [submissions]);

  const handleFilterSubmissions = async () => {
    setError('');
    setShowError(false);
    setIsLoading(true);

    if (!age || !gender || !temperature) {
      setError('Please fill in all required fields to view clothing suggestions.');
      setShowError(true);
      setIsLoading(false);
      return;
    }

    const ageNum = parseInt(age, 10);
    console.log('Starting filtering process...');

    let filtered = submissions.filter(sub => {
      const subTemperatures = sub.temperatures || [];
      const subDressCodes = sub.dressCodes || [];

      const matchesTemperature = subTemperatures.includes(temperature);
      const matchesDressCode = (!dressCode || subDressCodes.length === 0 || subDressCodes.includes('N/A')) ? true : subDressCodes.includes(dressCode);

      const matchesCriteria = (
        sub.gender === gender &&
        ageNum >= sub.minAge && ageNum <= sub.maxAge &&
        matchesTemperature &&
        matchesDressCode
      );

      console.log(`Filtering submission ${sub.id}:`, {
        genderMatch: sub.gender === gender,
        ageMatch: ageNum >= sub.minAge && ageNum <= sub.maxAge,
        temperatureMatch: matchesTemperature,
        dressCodeMatch: matchesDressCode,
        matchesCriteria
      });

      return matchesCriteria;
    });

    console.log('Filtered Submissions:', filtered);

    filtered = filtered.sort((a, b) => {
      if (b.isSticky && !a.isSticky) return 1;
      if (a.isSticky && !b.isSticky) return -1;
      return 0;
    });

    setClothingType(''); // Reset clothing type to "All Types"
    setInitialFilteredSubmissions(filtered);
    setFilteredSubmissions(filtered);

    if (filtered.length === 0) {
      console.log('Setting error because no items match:', filtered.length);
      setError('No items meet your criteria. Please adjust your filters.');
      setShowError(true);
    } else {
      try {
        await incrementSuggestionCount(); // Increment the suggestion count
        await incrementItemsSuggestedCount(filtered.length); // Increment the total items suggested count
      } catch (error) {
        console.error('Failed to increment suggestion count:', error);
      }
    }

    // Save filtered submissions to localStorage
    const filterState = {
      age,
      gender,
      temperature,
      dressCode,
      filteredSubmissions: filtered
    };
    localStorage.setItem('filterState', JSON.stringify(filterState));

    setIsLoading(false);
  };

  const handleClothingTypeChange = (e) => {
    const selectedType = e.target.value;
    setClothingType(selectedType);
    if (selectedType) {
      const furtherFiltered = initialFilteredSubmissions.filter(sub => sub.clothingType === selectedType);
      setFilteredSubmissions(furtherFiltered);
    } else {
      setFilteredSubmissions(initialFilteredSubmissions);
    }
  };

  const clearFilters = () => {
    setAge('');
    setGender('');
    setTemperature('');
    setDressCode('');
    setClothingType('');
    setInitialFilteredSubmissions([]);
    setFilteredSubmissions([]);
    setError('');
    setIsLoading(false);
    localStorage.removeItem('filterState');
  };

  const trackItemClick = (submission) => {
    window.gtag('event', 'select_content', {
      'content_type': 'suggestion_click',
      'item_id': submission.id, // if you have a unique identifier for each item
      'item_name': submission.itemName,
      'value': 1 // Optional: value of the event, if necessary
    });
  };

  const handleAgeChange = (e) => {
    const value = e.target.value;
    if (value === '' || (Number(value) >= 0 && !value.startsWith('-'))) {
      setAge(value);
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      setCopySuccess('Link copied!');
      setTimeout(() => setCopySuccess(''), 2000); // Remove the success message after 2 seconds
    }, () => {
      setCopySuccess('Failed to copy link');
      setTimeout(() => setCopySuccess(''), 2000); // Remove the error message after 2 seconds
    });
  };

  const renderSuggestionsWithAds = () => {
    const items = [];
    filteredSubmissions.forEach((submission, index) => {
      items.push(
        <div key={index} className="submission-container">
          <div className="submission-spacing"></div>
          <a href={submission.link}
             target="_blank"
             rel="noopener noreferrer"
             onClick={() => trackItemClick(submission)}
             className="submission-link"
          >
            <img src={submission.imageUrl} alt={submission.itemName} className="submission-image" />
            <Typography variant="body1" className="submission-title">
              {submission.itemName} ({submission.storeName})
            </Typography>
          </a>
        </div>
      );
      if ((index + 1) % 5 === 0) {
        items.push(
          <div key={`ad-${index}-hyrovault`} className="submission-container ad-item">
            <a href="https://hyrovault.com" target="_blank" rel="noopener noreferrer">
              <img
                src={`${process.env.PUBLIC_URL}/upscaled_hyrovault_logo.png`}
                alt="HyroVault Logo"
                className="ad-logo"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = `${process.env.PUBLIC_URL}/fallback_logo.png`; // Fallback image
                }}
              />
            </a>
            <a href="https://hyrovault.com" target="_blank" rel="noopener noreferrer" className="ad-link">
              Suggested Item(s) No Longer Available? Post it on HyroVault!
            </a>
          </div>
        );
      }
      if ((index + 1) % 7 === 0) {
        items.push(
          <div key={`ad-${index}-share`} className="submission-container ad-item">
            <div className="share-container">
              <Typography variant="body1" className="share-title" sx={{ fontWeight: 'bold' }}>
                Share HyroFits!
              </Typography>
              <div className="share-buttons">
                <FacebookShareButton url={window.location.href}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
                <button
                  onClick={() => {
                    const encodedUrl = encodeURIComponent(window.location.href);
                    window.location.href = `fb-messenger://share/?link=${encodedUrl}`;
                  }}
                  className="messenger-share-button"
                >
                  <FacebookMessengerIcon size={32} round />
                </button>
                <WhatsappShareButton url={window.location.href}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
                <TwitterShareButton url={window.location.href}>
                  <TwitterIcon size={32} round />
                </TwitterShareButton>
                <EmailShareButton url={window.location.href}>
                  <EmailIcon size={32} round />
                </EmailShareButton>
                <button onClick={handleCopyLink} className="copy-link-button">
                  <LinkIcon />
                </button>
              </div>
              {copySuccess && <Typography className="copy-success">{copySuccess}</Typography>}
            </div>
          </div>
        );
      }
    });
    if (filteredSubmissions.length < 8) {
      items.push(
        <div key={`ad-bottom-share`} className="submission-container ad-item">
          <div className="share-container">
            <Typography variant="body1" className="share-title">
              Share HyroFits!
            </Typography>
            <div className="share-buttons">
              <FacebookShareButton url={window.location.href}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <button
                onClick={() => {
                  const encodedUrl = encodeURIComponent(window.location.href);
                  window.location.href = `fb-messenger://share/?link=${encodedUrl}`;
                }}
                className="messenger-share-button"
              >
                <FacebookMessengerIcon size={32} round />
              </button>
              <WhatsappShareButton url={window.location.href}>
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <TwitterShareButton url={window.location.href}>
                <TwitterIcon size={32} round />
              </TwitterShareButton>
              <EmailShareButton url={window.location.href}>
                <EmailIcon size={32} round />
              </EmailShareButton>
              <button onClick={handleCopyLink} className="copy-link-button">
                <LinkIcon />
              </button>
            </div>
            {copySuccess && <Typography className="copy-success">{copySuccess}</Typography>}
          </div>
        </div>
      );
    }
    return items;
  };

  return (
    <div className="home-page-container">
      <Container component="main" maxWidth="md">
        <Card className="card-container" style={isLargeScreen ? { transform: 'scale(0.95)', transformOrigin: 'top' } : {}}>
          <CardContent>
            <div className="logo-container">
              <Link to="/">
                <img src={`${process.env.PUBLIC_URL}/upscaled_hyrofits_logo.png`} alt="HyroFits Logo" className="homepage-logo" />
              </Link>
            </div>
            <Link to="/" className="title-link">
              <Typography gutterBottom variant="h6" className="title">
                HyroFits Finds Clothes
              </Typography>
            </Link>
            <TextField
              label="Age"
              variant="outlined"
              fullWidth
              margin="dense"
              type="number"
              value={age}
              onChange={handleAgeChange}
              className="input-field"
              InputProps={{
                style: {
                  fontSize: '1rem', // Ensure this is consistent with other fields
                },
                inputProps: {
                  min: 0,
                  onKeyDown: (e) => {
                    if (e.key === 'ArrowDown' || e.key === '-') {
                      e.preventDefault();
                    }
                  }
                }
              }}
              InputLabelProps={{
                style: {
                  fontSize: '1rem', // Ensure this is consistent with other fields
                },
              }}
            />
            <TextField
              label="Gender"
              select
              variant="outlined"
              fullWidth
              margin="dense"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              className="input-field"
              SelectProps={{
                style: {
                  fontSize: '1rem', // Ensure this is consistent with other fields
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: '1rem', // Ensure this is consistent with other fields
                },
              }}
            >
              {['Man', 'Woman', 'Boy', 'Girl'].map((option) => (
                <MenuItem key={option} value={option} style={{ fontSize: '1rem' }}>{option}</MenuItem>
              ))}
            </TextField>
            <TextField
              label="Outside Weather"
              select
              variant="outlined"
              fullWidth
              margin="dense"
              value={temperature}
              onChange={(e) => setTemperature(e.target.value)}
              className="input-field"
              SelectProps={{
                style: {
                  fontSize: '1rem', // Ensure this is consistent with other fields
                },
              }}
              InputLabelProps={{
                style: {
                  fontSize: '1rem', // Ensure this is consistent with other fields
                },
              }}
            >
                            {['Hot', 'Warm', 'Cool', 'Cold', 'Very Cold'].map((temp) => (
                <MenuItem key={temp} value={temp} style={{ fontSize: '1rem' }}>{temp}</MenuItem>
              ))}
            </TextField>
            <TextField
              label="Dress Code"
              select
              variant="outlined"
              fullWidth
              margin="dense"
              value={dressCode}
              onChange={(e) => setDressCode(e.target.value)}
              SelectProps={{
                displayEmpty: true,
                style: {
                  fontSize: '1rem', // Ensure this is consistent with other fields
                },
              }}
              InputLabelProps={{
                shrink: true,
                style: {
                  fontSize: '1rem', // Ensure this is consistent with other fields
                },
              }}
              className="input-field"
            >
              <MenuItem value="" style={{ fontSize: '1rem' }}>Any</MenuItem>
              {['Casual', 'Professional', 'Formal', 'Activewear'].map((option) => (
                <MenuItem key={option} value={option} style={{ fontSize: '1rem' }}>{option}</MenuItem>
              ))}
            </TextField>
            <div className="buttons-container" style={{ marginTop: '4px' }}>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={handleFilterSubmissions}
                className="action-button"
                disabled={isLoading}
                style={{ fontSize: '0.7rem', padding: '2px 8px' }}
              >
                {isLoading ? <CircularProgress size={16} color="inherit" /> : "Go"}
              </Button>
              {initialFilteredSubmissions.length > 0 && (
                <Button
                  type="button"
                  variant="outlined"
                  color="secondary"
                  onClick={clearFilters}
                  className="action-button"
                  style={{ fontSize: '0.7rem', padding: '2px 8px' }}
                >
                  Clear
                </Button>
              )}
            </div>
            {showError && (
              <Typography className="error-message" style={{ fontSize: '1rem', marginTop: '4px' }}>
                {error}
              </Typography>
            )}
            {isLoading ? (
              <CircularProgress />
            ) : filteredSubmissions.length > 0 ? (
              <>
                {initialFilteredSubmissions.length > 0 && (
                  <TextField
                    label="Filter by Clothing Type"
                    select
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    value={clothingType}
                    onChange={handleClothingTypeChange}
                    SelectProps={{
                      displayEmpty: true,
                      style: {
                        fontSize: '1rem', // Ensure this is consistent with other fields
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        fontSize: '1rem', // Ensure this is consistent with other fields
                      },
                    }}
                    className="input-field"
                  >
                    <MenuItem value="" style={{ fontSize: '1rem' }}>All Types</MenuItem>
                    {['Tops', 'Bottoms', 'Shoes', 'Accessories'].map((type) => (
                      <MenuItem key={type} value={type} style={{ fontSize: '1rem' }}>{type}</MenuItem>
                    ))}
                  </TextField>
                )}
                <Box className="suggestions-list">
                  {renderSuggestionsWithAds()}
                </Box>
              </>
            ) : null}
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default HomePage;
