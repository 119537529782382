// ContactForm.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Box, Button, Container, TextField, Typography, Card, CardContent, CircularProgress } from '@mui/material';
import moment from 'moment-timezone';
import { saveContactSubmission } from '../utils/dbUtilities';
import { useAuth } from '../contexts/AuthContext';
import './ContactForm.css';

const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [submissionStatus, setSubmissionStatus] = useState('');
    const [mathChallenge, setMathChallenge] = useState({});
    const [mathAnswer, setMathAnswer] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [messageError, setMessageError] = useState('');
    const [mathAnswerError, setMathAnswerError] = useState('');

    const { currentUser } = useAuth();

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top when the component mounts

        if (!isSubmitted) {
            generateMathChallenge();
        }
    }, [isSubmitted]);

    const generateMathChallenge = () => {
        const num1 = Math.floor(Math.random() * 10);
        const num2 = Math.floor(Math.random() * 10);
        setMathChallenge({ num1, num2 });
    };

    const isValidEmail = email => /\S+@\S+\.\S+/.test(email);

    const getUsername = () => {
        return currentUser ? (currentUser.username || currentUser.displayName || currentUser.email.split('@')[0]) : null;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = true;

        if (!name.trim()) {
            setNameError('Please enter your name.');
            isValid = false;
        } else {
            setNameError('');
        }

        if (!isValidEmail(email)) {
            setEmailError('Please enter a valid email address.');
            isValid = false;
        } else {
            setEmailError('');
        }

        if (!message.trim()) {
            setMessageError('Please enter your message.');
            isValid = false;
        } else {
            setMessageError('');
        }

        if (parseInt(mathAnswer) !== mathChallenge.num1 + mathChallenge.num2) {
            setMathAnswerError('Incorrect math CAPTCHA answer.');
            isValid = false;
        } else {
            setMathAnswerError('');
        }

        if (isValid) {
            const timestampEST = moment().tz('America/New_York').format('YYYY-MM-DD HH:mm:ss');
            const submissionData = {
                name, 
                email, 
                message, 
                timestamp: timestampEST,
                username: getUsername(),
            };
    
            try {
                await saveContactSubmission(submissionData, 'newApp'); // Pass 'newApp' as the appId
                setSubmissionStatus('Your message has been sent successfully!');
                setIsSubmitted(true);
                setName('');
                setEmail('');
                setMessage('');
                setMathAnswer('');
            } catch (error) {
                setSubmissionStatus('Failed to send message. Please try again.');
            }
        } else {
            generateMathChallenge();
        }
    };

    return (
       <div className="contact-page-container">
           <Container component="main" maxWidth="md">
               <Card className="card-container">
                   <CardContent>
                       <div className="logo-container">
                           <Link to="/">
                               <img src={`${process.env.PUBLIC_URL}/upscaled_hyrofits_logo.png?${Date.now()}`} alt="HyroFits Logo" className="homepage-logo" />
                           </Link>
                       </div>
                       <Link to="/" className="title-link">
                           <Typography gutterBottom variant="h6" className="title">
                               Contact Us
                           </Typography>
                       </Link>
                       <form onSubmit={handleSubmit} noValidate>
                           <TextField
                               label="Name"
                               variant="outlined"
                               fullWidth
                               required
                               value={name}
                               onChange={(e) => setName(e.target.value)}
                               error={!!nameError}
                               helperText={nameError}
                               margin="dense"
                               className="input-field"
                               InputProps={{
                                   style: { fontSize: '1rem' }
                               }}
                               InputLabelProps={{
                                   style: { fontSize: '1rem' }
                               }}
                           />
                           <TextField
                               label="Email"
                               variant="outlined"
                               fullWidth
                               required
                               value={email}
                               onChange={(e) => setEmail(e.target.value)}
                               error={!!emailError}
                               helperText={emailError}
                               margin="dense"
                               className="input-field"
                               InputProps={{
                                   style: { fontSize: '1rem' }
                               }}
                               InputLabelProps={{
                                   style: { fontSize: '1rem' }
                               }}
                           />
                           <TextField
                               label="Message"
                               variant="outlined"
                               fullWidth
                               multiline
                               rows={4}
                               required
                               value={message}
                               onChange={(e) => setMessage(e.target.value)}
                               error={!!messageError}
                               helperText={messageError}
                               margin="dense"
                               className="input-field"
                               InputProps={{
                                   style: { fontSize: '1rem' }
                               }}
                               InputLabelProps={{
                                   style: { fontSize: '1rem' }
                               }}
                           />
                           {!isSubmitted && (
                               <>
                                   <Typography>
                                       Solve this to prove you're not a robot: {mathChallenge.num1} + {mathChallenge.num2} = ?
                                   </Typography>
                                   <TextField
                                       fullWidth
                                       variant="outlined"
                                       required
                                       value={mathAnswer}
                                       onChange={(e) => setMathAnswer(e.target.value)}
                                       error={!!mathAnswerError}
                                       helperText={mathAnswerError}
                                       margin="dense"
                                       className="input-field math-captcha"
                                   />
                               </>
                           )}
                           {submissionStatus && (
                               <Typography color="primary" className="success-message">
                                   {submissionStatus}
                               </Typography>
                           )}
                           <div className="buttons-container">
                               <Button
                                   type="submit"
                                   variant="contained"
                                   color="primary"
                                   size="large"
                                   className="action-button"
                                   style={{ fontSize: '0.8rem', padding: '4px 12px' }}
                               >
                                   Send
                               </Button>
                           </div>
                       </form>
                   </CardContent>
               </Card>
           </Container>
       </div>
   );
};

export default ContactForm;
