//Fits.js

import React, { useState, useContext } from 'react';
import { FitsContext } from '../contexts/FitsContext';
import { Card, CardContent, Typography, TextField, Button, MenuItem, Container } from '@mui/material';
import './Fits.css';
import axios from 'axios';

const Fits = () => {
    const { submissions } = useContext(FitsContext);
    const [destination, setDestination] = useState('');
    const [location, setLocation] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [temperature, setTemperature] = useState('');
    const [dressCode, setDressCode] = useState('');
    const [outfitSuggestions, setOutfitSuggestions] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // Function to normalize text by removing dashes, parentheses, and leading/trailing spaces
    const normalizeText = (text) => {
        return text
            .toLowerCase() // Convert text to lower case
            .replace(/[-"']/g, '') // Remove dashes, quotes, and apostrophes
            .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
            .trim(); // Remove leading and trailing spaces
    };

    const generateDescriptions = async () => {
        try {
            const destinationDescResponse = await axios.post(
                'https://api.openai.com/v1/completions',
                {
                    model: "gpt-3.5-turbo-instruct",
                    prompt: `Provide a brief 10-word description for the destination "${destination}".`,
                    max_tokens: 60,
                    temperature: 0.7,
                },
                { headers: { 'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_KEY}` } }
            );
            const locationDescResponse = await axios.post(
                'https://api.openai.com/v1/completions',
                {
                    model: "gpt-3.5-turbo-instruct",
                    prompt: `Provide a brief 10-word description for the location "${location}".`,
                    max_tokens: 60,
                    temperature: 0.7,
                },
                { headers: { 'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_KEY}` } }
            );
            const destinationDescription = destinationDescResponse.data.choices[0].text.trim();
            const locationDescription = locationDescResponse.data.choices[0].text.trim();
            console.log("Destination Description:", destinationDescription);
            console.log("Location Description:", locationDescription);
            return {
                destinationDescription,
                locationDescription
            };
        } catch (error) {
            console.error("Error generating descriptions:", error);
            setError('Failed to generate descriptions.');
            return {}; // Ensure function returns even in case of error to prevent further execution
        }
    };

    const handleGenerateOutfitSuggestions = async (event) => {
        event.preventDefault();
        setError('');
        setOutfitSuggestions([]);
        setIsLoading(true); // Start loading

        if (!destination || !location || submissions.length === 0) {
            setError('Please enter all details and ensure there are items submitted for outfit suggestions.');
            setIsLoading(false); // Stop loading if there's an immediate error
            return;
        }

        const descriptions = await generateDescriptions();
        if (!descriptions.destinationDescription || !descriptions.locationDescription) {
            setIsLoading(false); // Stop loading if descriptions are missing
            return; // Exit function if descriptions are missing
        }

        // Pre-filter submissions based on user's criteria including dress code
        const userFilteredSubmissions = submissions.filter(sub => {
            console.log("Item dress codes:", sub.dressCodes); // Debug: Log the dress codes of each item
            console.log("Selected dress code:", dressCode); // Debug: Log the selected dress code
            return (
                (sub.gender === gender || sub.gender === 'Unisex') &&
                parseInt(age) >= sub.minAge && parseInt(age) <= sub.maxAge &&
                sub.temperatures.includes(temperature) &&
                (sub.dressCodes?.includes(dressCode) || []) // Safely check dressCodes
            );
        });   

        // Log the filtered submissions
        console.log("Filtered Submissions for GPT:", userFilteredSubmissions.map(sub => sub.itemName)); 

        if (userFilteredSubmissions.length === 0) {
            setError('No items meet your filters. Please adjust your filters or add more items.');
            setIsLoading(false); // Stop loading since no items matched the filters
            return;
        }

        const itemNamesList = userFilteredSubmissions.map(sub => `- ${sub.itemName}`).join('\n');
        console.log("Item names list sent to GPT:", itemNamesList);

        const promptForSuggestions = `Given the following list of items, suggest suitable items for an outing based on the destination "${descriptions.destinationDescription}", location "${descriptions.locationDescription}", and dress code "${dressCode}". Please only suggest items from the list below. Provide as many suggestions as possible:\n${itemNamesList}`;

        try {
            const suggestionsResponse = await axios.post(
                'https://api.openai.com/v1/completions',
                {
                    model: "gpt-3.5-turbo-instruct",
                    prompt: promptForSuggestions,
                    max_tokens: 100,
                    temperature: 0.5,
                },
                { headers: { 'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_KEY}` } }
            );
        
            const suggestedItemNames = suggestionsResponse.data.choices[0].text
                .trim()
                .split('\n')
                .map(line => {
                    // Extract the item name from the GPT-3.5 suggestion
                    const match = line.match(/^\d*\.?\s*([^:]+?)(?=\s*:\s*This|\s*$)/);
                    if (match) {
                        return normalizeText(match[1]);
                    }
                    return '';
                })
                .filter(name => name); // Remove any empty strings resulting from the regex.
        
            console.log("Extracted GPT-3.5 Suggested Item Names:", suggestedItemNames);
        
            const matchedItems = userFilteredSubmissions.filter(sub =>
                suggestedItemNames.some(name => normalizeText(sub.itemName).includes(name))
            );
        
            console.log("Matched Items Based on GPT-3.5 Suggestions:", matchedItems.map(item => item.itemName));
        
            setOutfitSuggestions(matchedItems.map(sub => ({
                itemName: `${sub.itemName} from ${sub.storeName}`,
                link: sub.link,
                imageUrl: sub.imageUrl
            })));
        } catch (error) {
            console.error('Error during the suggestion generation process:', error);
            setError('Error generating outfit suggestions. Please try again later.');
        } finally {
            setIsLoading(false); // Ensure loading is stopped regardless of outcome
        }      
    };

    const handleClearOutfitSuggestions = () => {
        setOutfitSuggestions([]);
        setDestination('');
        setLocation('');
        setAge('');
        setGender('');
        setTemperature('');
        setDressCode(''); // Reset dress code
        setError('');
        setIsLoading(false); // Reset loading state
    };

    const shouldShowClearButton = destination || location || submissions.length > 0;

    return (
        <div className="fits-form-container">
            <Container component="main" maxWidth="sm" className="fits-form">
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Generate Outfit Suggestions
                        </Typography>
                        <form onSubmit={handleGenerateOutfitSuggestions}>
                            <TextField
                                label="Where are you going? (e.g., Montgomery Mall)"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={destination}
                                onChange={(e) => setDestination(e.target.value)}
                            />
                            <TextField
                                label="Location (e.g., Washington, DC)"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                            />
                            <TextField
                                label="How old are you?"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                type="number"
                                value={age}
                                onChange={(e) => setAge(e.target.value)}
                            />
                            <TextField
                                label="Gender"
                                variant="outlined"
                                select
                                fullWidth
                                margin="normal"
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                            >
                                {['Man', 'Woman', 'Boy', 'Girl'].map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                label="Outside Weather"
                                select
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={temperature}
                                onChange={(e) => setTemperature(e.target.value)}
                            >
                                {['Hot', 'Warm', 'Cool', 'Cold', 'Very Cold'].map(temp => (
                                    <MenuItem key={temp} value={temp}>
                                        {temp}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {/* Dress Code Dropdown */}
                            <TextField
                                label="Dress Code"
                                select
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={dressCode}
                                onChange={(e) => setDressCode(e.target.value)}
                            >
                                {['Casual', 'Professional', 'Formal', 'Activewear'].map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Button
                               type="submit"
                               variant="contained"
                               color="primary"
                               className="generate-button"
                               disabled={!destination || !location || !age || !gender || !temperature || !dressCode || isLoading}
                           >
                               {isLoading ? "Generating..." : "Generate Outfit Suggestions"}
                           </Button>
                           {shouldShowClearButton && (
                               <Button
                                   type="button"
                                   variant="contained"
                                   color="secondary"
                                   onClick={handleClearOutfitSuggestions}
                                   style={{ marginLeft: '10px' }}
                               >
                                   Clear
                               </Button>
                           )}
                                               </form>
                        {error && (
                            <Typography color="error" style={{ marginTop: '10px' }}>
                                {error}
                            </Typography>
                        )}
                        {outfitSuggestions.length > 0 && (
                            <div className="suggestions-list">
                                {outfitSuggestions.map((suggestion, index) => (
                                    <div key={index} style={{ marginBottom: '20px', textAlign: 'center' }}>
                                        <a href={suggestion.link} target="_blank" rel="noopener noreferrer" style={{ display: 'inline-block', color: '#1a0dab', textDecoration: 'none', cursor: 'pointer' }}>
                                            <img src={suggestion.imageUrl} alt={suggestion.itemName} style={{ maxWidth: '100%', maxHeight: '200px', marginBottom: '10px' }} />
                                            <Typography variant="body1" style={{ color: '#1a0dab', textDecoration: 'none' }}>
                                                {suggestion.itemName}
                                            </Typography>
                                        </a>
                                    </div>
                                ))}
                            </div>
                        )}
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
};

export default Fits;

