// contexts/MessagesContext.js

import React, { createContext, useContext, useState, useCallback, useRef } from 'react';
import { sendMessageToDb, updateMessageReadStatus, generateConversationId } from '../utils/dbUtilities';
import { useAuth } from './AuthContext';
import { useBlock } from './BlockContext';
import { db } from '../utils/firebaseConfig';
import { collection, query, where, onSnapshot } from 'firebase/firestore';

const MessagesContext = createContext();

export const useMessages = () => useContext(MessagesContext);

export const MessagesProvider = ({ children }) => {
    const [messages, setMessages] = useState([]);
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
    const { currentUser } = useAuth();
    const { isUserBlocked } = useBlock();
    const allUnsubscribeFunctions = useRef([]);

    const fetchMessages = useCallback(async () => {
        if (!currentUser) {
            console.log('No user, exiting fetchMessages');
            return;
        }

        allUnsubscribeFunctions.current.forEach(unsubscribe => {
            if (typeof unsubscribe === 'function') {
                unsubscribe();
            } else {
                console.error('Invalid unsubscribe function encountered');
            }
        });
        allUnsubscribeFunctions.current = [];

        try {
            const q = query(collection(db, 'messages'), where('receiver', '==', currentUser.displayName), where('read', '==', false));

            const unsubscribe = onSnapshot(q, querySnapshot => {
                let localUnreadCount = 0;
                const newMessages = [];
                querySnapshot.forEach(doc => {
                    const message = doc.data();
                    if (!isUserBlocked(message.sender)) {
                        newMessages.push({ id: doc.id, ...message });
                        localUnreadCount++;
                    }
                });
                setMessages(newMessages);
                setUnreadMessagesCount(localUnreadCount);
            });

            if (typeof unsubscribe === 'function') {
                allUnsubscribeFunctions.current.push(unsubscribe);
            } else {
                console.error('Expected unsubscribe to be a function, but got:', unsubscribe);
            }
        } catch (error) {
            console.error("Error fetching messages", error);
        }
    }, [currentUser, isUserBlocked]);

    const sendMessage = async (receiverUsername, messageContent) => {
        if (!currentUser) {
            console.log('No current user, aborting sendMessage');
            return;
        }

        if (isUserBlocked(receiverUsername)) {
            console.log(`User ${receiverUsername} is blocked, aborting sendMessage`);
            return;
        }

        const senderIdentifier = currentUser.displayName || currentUser.username || currentUser.email.split('@')[0];
        let conversationId = generateConversationId(senderIdentifier, receiverUsername);

        const messageData = {
            text: messageContent.text || '',
            image: messageContent.image || '',
            timestamp: new Date().toISOString(),
            read: false,
            sender: senderIdentifier,
            receiver: receiverUsername,
            conversationId
        };

        try {
            const newMessageId = await sendMessageToDb(senderIdentifier, receiverUsername, messageData);
            console.log('Message sent successfully, newMessageId:', newMessageId);
            setMessages(prevMessages => [...prevMessages, { ...messageData, id: newMessageId }]);
        } catch (error) {
            console.error("Error sending message:", error);
        }
    };

    const markMessagesAsRead = async (conversationId) => {
        if (!currentUser) return;
        await updateMessageReadStatus(conversationId, currentUser.displayName);
        fetchMessages();
    };

    const value = {
      messages,
      fetchMessages, 
      sendMessage,
      unreadMessagesCount,
      markMessagesAsRead
  };

    return (
        <MessagesContext.Provider value={value}>
            {children}
        </MessagesContext.Provider>
    );
};

export default MessagesContext;
