// Footer.js

import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="app-footer">
      <div className="footer-alert">
        <span>High Usage = Errors. Refresh Page & Try Again.</span>
      </div>
      <div className="footer-links">
        <Link to="/info" className="footer-link">About</Link>
        <Link to="/contact" className="footer-link">Contact</Link>
      </div>
    </footer>
  );
}

export default Footer;
