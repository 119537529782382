// Navbar.js

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import './Navbar.css';
import 'typeface-bebas-neue';
import { ReactComponent as HomeIcon } from '../assets/home-icon.svg';
import { FaBars } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';
import { onItemsSuggestedCountChange } from '../utils/dbUtilities';

const NavBar = ({ isSidebarVisible, setIsSidebarVisible }) => {
  const { currentUser: authUser } = useAuth();
  const [itemsSuggestedCount, setItemsSuggestedCount] = useState(0);

  useEffect(() => {
    const unsubscribe = onItemsSuggestedCountChange(setItemsSuggestedCount);

    // Cleanup the listener on unmount
    return () => unsubscribe();
  }, []);

  // Format itemsSuggestedCount with commas
  const formattedItemsSuggestedCount = itemsSuggestedCount.toLocaleString();

  // Check if the current user is an admin
  const isAdmin = authUser && authUser.role === 'admin';

  return (
    <Container id="navbar">
      <div className="navbar-left">
        <Link to="/" className="navbar-link navbar-home">
          <HomeIcon className="home-icon" />
        </Link>
        {isAdmin && (
          <FaBars
            className="sidebar-icon"
            onClick={() => setIsSidebarVisible(!isSidebarVisible)}
          />
        )}
      </div>
      <div className="navbar-center">
        <div className="items-suggested-count">
          <span className="aisle-number">{formattedItemsSuggestedCount}</span> Items Shared
        </div>
      </div>
      <div className="navbar-right">
        <a href="https://hyrovault.com" target="_blank" rel="noopener noreferrer" className="navbar-link navbar-vault">
          <img src={`${process.env.PUBLIC_URL}/upscaled_hyrovault_logo.png`} alt="HyroVault Logo" className="navbar-icon" />
        </a>
      </div>
    </Container>
  );
};

export default NavBar;

