// FitsBoard.js

import React, { useState, useContext } from 'react';
import './FitsBoard.css';
import { useDropzone } from 'react-dropzone';
import { IoMdCloudUpload, IoMdImages, IoMdClose } from 'react-icons/io';
import { uploadImageToStorage, saveDataToDB, updateDataInDB } from '../utils/dbUtilities';
import { serverTimestamp } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { FitsContext } from '../contexts/FitsContext';

function formatDate(timestamp) {
    if (!timestamp || !timestamp.toDate) return 'Processing...';
    const date = timestamp.toDate();
    return new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
    }).format(date);
}

function FitsBoard() {
    const [itemName, setItemName] = useState('');
    const [storeName, setStoreName] = useState('');
    const [description, setDescription] = useState('');
    const [gender, setGender] = useState('');
    const [minAge, setMinAge] = useState('');
    const [maxAge, setMaxAge] = useState('');
    const [clothingType, setClothingType] = useState('');
    const [temperature, setTemperature] = useState({
        Hot: false,
        Warm: false,
        Cool: false,
        Cold: false,
        'Very Cold': false,
    });
    const [dressCode, setDressCode] = useState({
        Casual: false,
        Professional: false,
        Formal: false,
        Activewear: false,
    });
    const [inputUrl, setInputUrl] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const { currentUser } = useAuth();
    const { submissions, setSubmissions, deleteSubmission, updateSubmission } = useContext(FitsContext);

    const handleTemperatureChange = (event) => {
        setTemperature({ ...temperature, [event.target.value]: event.target.checked });
    };

    const handleDressCodeChange = (event) => {
        setDressCode({ ...dressCode, [event.target.value]: event.target.checked });
    };

    const handleEdit = (submission) => {
        console.log("Editing submission:", submission);
        setEditMode(true);
        setCurrentId(submission.id);
        setItemName(submission.itemName || '');
        setStoreName(submission.storeName || '');
        setDescription(submission.description || '');
        setGender(submission.gender || '');
        setMinAge(submission.minAge || '');
        setMaxAge(submission.maxAge || '');
        setClothingType(submission.clothingType || ''); // Ensure fallback to empty string if undefined
    
        const allTemperatures = { Hot: false, Warm: false, Cool: false, Cold: false, 'Very Cold': false };
        const temperatures = submission.temperatures || []; // Ensure it's always an array
        temperatures.forEach(temp => {
            allTemperatures[temp] = true;
        });
        setTemperature(allTemperatures);
    
        const allDressCodes = { Casual: false, Professional: false, Formal: false, Activewear: false };
        const dressCodes = submission.dressCodes || []; // Ensure it's always an array
        dressCodes.forEach(code => {
            allDressCodes[code] = true;
        });
        setDressCode(allDressCodes);
    
        setInputUrl(submission.link || '');
        setIsSticky(submission.isSticky || false);
    
        if (submission.imageUrl && typeof submission.imageUrl === 'string') {
            setSelectedImage(submission.imageUrl);
        } else {
            setSelectedImage(null);
        }
    
        // Scroll to the top of the page
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };       

    const handleCancelEdit = () => {
        console.log("Cancelling edit.");
        setItemName('');
        setStoreName('');
        setDescription('');
        setGender('');
        setMinAge('');
        setMaxAge('');
        setClothingType('');
        setTemperature({
            Hot: false,
            Warm: false,
            Cool: false,
            Cold: false,
            'Very Cold': false,
        });
        setDressCode({
            Casual: false,
            Professional: false,
            Formal: false,
            Activewear: false,
        });
        setInputUrl('');
        setSelectedImage(null);
        setIsSticky(false);
        setEditMode(false);
        setCurrentId(null);
    };

    const handleSubmit = async () => {
        console.log("Submitting data:", {
            itemName, storeName, description, gender, minAge, maxAge, clothingType, temperature, dressCode, inputUrl, isSticky
        });
    
        if (!currentUser) {
            alert('Please log in to submit an item.');
            return;
        }
    
        // Validate required fields
        if (!itemName || !gender || !minAge || !maxAge || !Object.values(temperature).includes(true)) {
            alert('Please fill in all required fields.');
            return;
        }
    
        setSubmitting(true);
        let url = selectedImage instanceof File ? null : selectedImage; // Check if selectedImage is a File object (new upload) or a string (existing URL)
        if (selectedImage instanceof File) {
            try {
                url = await uploadImageToStorage(selectedImage, `fitsBoard/${selectedImage.name}`);
            } catch (error) {
                console.error('Error uploading image:', error);
                setSubmitting(false);
                return;
            }
        }
        const itemData = {
            itemName,
            storeName,
            description,
            gender,
            minAge,
            maxAge,
            clothingType,
            temperatures: Object.keys(temperature).filter(temp => temperature[temp]),
            dressCodes: Object.keys(dressCode).filter(code => dressCode[code]),
            imageUrl: url, // Use the new URL if a new image was uploaded, otherwise use the existing URL
            link: inputUrl,
            isSticky,
            timestamp: serverTimestamp(),
        };
    
        if (editMode) {
            console.log("Updating existing submission:", itemData);
            await updateSubmission(currentId, itemData);
            setEditMode(false);
        } else {
            console.log("Creating new submission:", itemData);
            try {
                const newItemId = await saveDataToDB('fitsBoardItems', itemData);
                setSubmissions([{ ...itemData, id: newItemId }, ...submissions]);
            } catch (error) {
                console.error('Error saving item to database:', error);
            }
        }
    
        handleCancelEdit();
        setSubmitting(false);
    };      

    const handleDelete = async (event, id) => {
        event.stopPropagation();
        if (window.confirm('Are you sure you want to delete this submission?')) {
            console.log(`Attempting to delete submission with ID: ${id}`);
            await deleteSubmission(id);
        }
    };

    const handleImageClick = (event, link) => {
        event.stopPropagation();
        window.open(link, '_blank');
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            console.log('Dropped files:', acceptedFiles);
            setSelectedImage(acceptedFiles[0]);
        },
    });

    submissions.sort((a, b) => {
        let dateA = a.timestamp ? (a.timestamp.toDate ? a.timestamp.toDate() : new Date(a.timestamp)) : new Date(0);
        let dateB = b.timestamp ? (b.timestamp.toDate ? b.timestamp.toDate() : new Date(b.timestamp)) : new Date(0);
        return dateB - dateA;
    });

    const [filterStore, setFilterStore] = useState('');
    const [filterGender, setFilterGender] = useState('');
    const [filterClothingType, setFilterClothingType] = useState('');

    const handleFilterChange = (setter) => (event) => {
        setter(event.target.value);
    };

    const filteredSubmissions = submissions.filter(submission => {
        return (filterStore ? submission.storeName === filterStore : true) &&
            (filterGender ? submission.gender === filterGender : true) &&
            (filterClothingType ? submission.clothingType === filterClothingType : true);
    });

    return (
        <div className="fitsBoardContainer">
            <div className="contentContainer">
                <input 
                    type="text" 
                    className="form-input" 
                    value={itemName} 
                    onChange={e => setItemName(e.target.value)} 
                    placeholder="Item Name" 
                />
                <select 
                    className="form-select" 
                    value={storeName} 
                    onChange={e => setStoreName(e.target.value)}
                >
                    <option value="">Select Store</option>
                    <option value="J. Crew">J. Crew</option>
                    <option value="Mango">Mango</option>
                    <option value="River Island">River Island</option>
                    <option value="Ben Sherman">Ben Sherman</option>
                    <option value="Adidas">Adidas</option>
                    <option value="White House Black Market">White House Black Market</option>
                    <option value="Chico's">Chico's</option>
                    <option value="Ann Taylor">Ann Taylor</option>
                    <option value="Anthropologie">Anthropologie</option>
                </select>
                <textarea 
                    className="form-textarea" 
                    value={description} 
                    onChange={e => setDescription(e.target.value)} 
                    placeholder="Description" 
                />
                <select 
                    className="form-select" 
                    value={gender} 
                    onChange={e => setGender(e.target.value)}
                >
                    <option value="">Select Gender</option>
                    <option value="Man">Man</option>
                    <option value="Woman">Woman</option>
                    <option value="Boy">Boy</option>
                    <option value="Girl">Girl</option>
                </select>
                <div className="clothing-type-section">
                    <h3>Clothing Type</h3>
                    <select 
                        className="form-select" 
                        value={clothingType} 
                        onChange={e => setClothingType(e.target.value)}
                    >
                        <option value="">Select Type</option>
                        <option value="Tops">Tops</option>
                        <option value="Bottoms">Bottoms</option>
                        <option value="Shoes">Shoes</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
                <div className="age-range-container">
                    <input 
                        type="number" 
                        className="form-input age-input" 
                        value={minAge} 
                        onChange={e => setMinAge(e.target.value)} 
                        placeholder="Min Age" 
                    />
                    <input 
                        type="number" 
                        className="form-input age-input" 
                        value={maxAge} 
                        onChange={e => setMaxAge(e.target.value)} 
                        placeholder="Max Age" 
                    />
                </div>
                <div className="temperature-section">
                    <h3>Temperature</h3>
                    <div className="temperature-row">
                        {Object.entries(temperature).map(([key, value]) => (
                            <label key={key}>
                                <input 
                                    type="checkbox" 
                                    value={key} 
                                    checked={value} 
                                    onChange={handleTemperatureChange} 
                                /> {key}
                            </label>
                        ))}
                    </div>
                </div>
                <div className="dress-code-section">
                    <h3>Dress Code</h3>
                    <div className="dress-code-row">
                        {Object.entries(dressCode).map(([key, value]) => (
                            <label key={key}>
                                <input 
                                    type="checkbox" 
                                    value={key} 
                                    checked={value} 
                                    onChange={handleDressCodeChange} 
                                /> {key}
                            </label>
                        ))}
                    </div>
                </div>
                <div className="sticky-section" style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <h3 style={{ display: 'inline-block', marginBottom: '0' }}>Sticky</h3>
                    <div style={{ textAlign: 'center' }}>
                        <label style={{ fontWeight: 'normal', display: 'inline-block', marginTop: '10px' }}>
                            <input
                                type="checkbox"
                                checked={isSticky}
                                onChange={() => setIsSticky(!isSticky)}
                                style={{ marginRight: '0px' }}
                            /> Yes
                        </label>
                    </div>
                </div>
                <input 
                    type="text" 
                    className="form-input" 
                    value={inputUrl} 
                    onChange={e => setInputUrl(e.target.value)} 
                    placeholder="Paste URL here" 
                />
                <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    <div className="dropzone-content">
                        {selectedImage && (selectedImage instanceof File || selectedImage instanceof Blob) ? (
                            <img 
                                src={URL.createObjectURL(selectedImage)} 
                                alt="Preview" 
                                className="uploaded-image-preview" 
                            />
                        ) : selectedImage ? (
                            <img 
                                src={selectedImage} 
                                alt="Preview" 
                                className="uploaded-image-preview" 
                            />
                        ) : (
                            <p>Drag 'n' drop some files here, or click to select files</p>
                        )}
                        <IoMdCloudUpload size={50} className="upload-icon" />
                        <p>Upload Image (Drag & Drop or Click)</p>
                        <IoMdImages size={50} className="images-icon" />
                    </div>
                </div>
                <button 
                    className="form-button" 
                    onClick={handleSubmit} 
                    disabled={submitting}
                >
                    {submitting ? 'Submitting...' : 'Submit'}
                </button>
                <button 
                    className="form-button cancel-button" 
                    onClick={handleCancelEdit}
                    style={{ display: editMode ? 'inline-block' : 'none' }}
                >
                    Cancel Edit
                </button>
            </div>
            <div className="submissionCountContainer">
                <h3>Total Submissions: {submissions.length}</h3>
                <select 
                    className="form-select" 
                    value={filterStore} 
                    onChange={handleFilterChange(setFilterStore)}
                >
                    <option value="">Filter by Store</option>
                    <option value="J. Crew">J. Crew</option>
                    <option value="Mango">Mango</option>
                    <option value="River Island">River Island</option>
                    <option value="Ben Sherman">Ben Sherman</option>
                    <option value="Adidas">Adidas</option>
                    <option value="White House Black Market">White House Black Market</option>
                    <option value="Chico's">Chico's</option>
                    <option value="Ann Taylor">Ann Taylor</option>
                    <option value="Anthropologie">Anthropologie</option>
                </select>
                <select 
                    className="form-select" 
                    value={filterGender} 
                    onChange={handleFilterChange(setFilterGender)}
                >
                    <option value="">Filter by Gender</option>
                    <option value="Man">Man</option>
                    <option value="Woman">Woman</option>
                    <option value="Boy">Boy</option>
                    <option value="Girl">Girl</option>
                </select>
                <select 
                    className="form-select" 
                    value={filterClothingType} 
                    onChange={handleFilterChange(setFilterClothingType)}
                >
                    <option value="">Filter by Clothing Type</option>
                    <option value="Tops">Tops</option>
                    <option value="Bottoms">Bottoms</option> 
                    <option value="Shoes">Shoes</option>
                    <option value="Other">Other</option>
                </select>
            </div>
            {filteredSubmissions.map((submission, index) => (
                <div key={index} className="submittedContent">
                    <div className="imageContainer" onClick={(e) => handleImageClick(e, submission.link)}>
                        <img src={submission.imageUrl} alt="Uploaded content" className="urlPreviewImage"
                            onError={() => console.log(`Failed to load image for submission ID: ${submission.id}`)} />
                    </div>
                    <button className="deleteButton" onClick={(e) => handleDelete(e, submission.id)}>
                        <IoMdClose />
                    </button>
                    <button className="editButton" onClick={() => handleEdit(submission)}>
                        Edit
                    </button>
                    <div>
                        <p className="submittedItemName">Item Name: {submission.itemName}</p>
                        <p className="submittedStoreName">Store: {submission.storeName}</p>
                        <p className="submittedDescription">Description: {submission.description}</p>
                        <p className="submittedGender">Gender: {submission.gender}</p>
                        <p className="submittedAgeRange">Age Range: {submission.minAge} - {submission.maxAge}</p>
                        <p className="submittedTemperatures">Temperatures: {submission.temperatures ? submission.temperatures.join(', ') : 'N/A'}</p>
                        <p className="submittedDressCodes">Dress Code: {submission.dressCodes ? submission.dressCodes.join(', ') : 'N/A'}</p>
                        <p className="submittedClothingType">Clothing Type: {submission.clothingType || 'N/A'}</p>
                        {submission.isSticky && <p className="submittedSticky">Sticky: Yes</p>} {/* Display Sticky status */}
                        <p className="submittedTimestamp">Submitted: {formatDate(submission.timestamp)}</p>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default FitsBoard;
