// AdminDashboard.js

import React, { useState, useEffect, Suspense } from 'react';
import { getAllContactSubmissions, getSuggestionCount, getItemsSuggestedCount } from '../utils/dbUtilities';
import './AdminDashboard.css';

const ManagePosts = React.lazy(() => import('./ManagePosts'));

const AdminDashboard = () => {
    const [activeTab, setActiveTab] = useState('posts');
    const [contactSubmissions, setContactSubmissions] = useState([]);
    const [suggestionCount, setSuggestionCount] = useState(0);
    const [itemsSuggestedCount, setItemsSuggestedCount] = useState(0);
  
    useEffect(() => {
      getAllContactSubmissions().then(submissions => {
        const sortedSubmissions = submissions
          .map(submission => ({
            ...submission,
            timestamp: submission.timestamp?.toDate ? submission.timestamp.toDate() : new Date(submission.timestamp)
          }))
          .sort((a, b) => b.timestamp - a.timestamp);
        setContactSubmissions(sortedSubmissions);
      }).catch(error => {
        console.error('Failed to load contact submissions:', error);
      });
  
      const fetchCounts = async () => {
        try {
          const suggestionCount = await getSuggestionCount();
          const itemsCount = await getItemsSuggestedCount();
          console.log('Fetched suggestion count:', suggestionCount); // Debugging log
          console.log('Fetched items suggested count:', itemsCount); // Debugging log
          setSuggestionCount(suggestionCount);
          setItemsSuggestedCount(itemsCount);
        } catch (error) {
          console.error('Failed to fetch counts:', error);
        }
      };
  
      fetchCounts();
    }, []);
  
    const handleTabClick = (tabName) => {
      setActiveTab(tabName);
    };
  
    const getTabClass = (tabName) => {
      return `admin-dashboard-tab-button ${activeTab === tabName ? 'active' : ''}`;
    };
  
    const renderContactSubmission = (submission) => {
      return (
        <div key={submission.id} className="report-item">
          <p>From: {submission.name} ({submission.email}){submission.username ? ` (${submission.username})` : ''}</p>
          <p>Message: {submission.message}</p>
          <p>Sent: {submission.timestamp ? submission.timestamp.toLocaleString() : "Date not available"}</p>
        </div>
      );
    };
  
    return (
      <div className="admin-dashboard-container">
        <div className="admin-dashboard-header">
          <h1>Admin Dashboard</h1>
        </div>
        <ul className="admin-dashboard-tabs">
          <li className={getTabClass('posts')} onClick={() => handleTabClick('posts')}>Manage Posts</li>
          <li className={getTabClass('contact')} onClick={() => handleTabClick('contact')}>Contact Submissions</li>
          <li className={getTabClass('statistics')} onClick={() => handleTabClick('statistics')}>Total Suggestions</li>
        </ul>
        <div className="admin-dashboard-content">
          <Suspense fallback={<div>Loading...</div>}>
            {activeTab === 'posts' && <ManagePosts />}
            {activeTab === 'contact' && (
              <div>
                <h2>Contact Form Submissions</h2>
                <div className="reports-list">
                  {contactSubmissions.map(renderContactSubmission)}
                </div>
              </div>
            )}
            {activeTab === 'statistics' && (
              <div className="statistics-content">
                <h2>Total Suggestions Made</h2>
                <p>Total Requests: {suggestionCount}</p>
                <p>Total Items Suggested: {itemsSuggestedCount}</p>
              </div>
            )}
          </Suspense>
        </div>
      </div>
    );
  };
  
  export default AdminDashboard;
