// theme.js

import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1877F2', // Meta's blue color
    },
    secondary: {
      main: '#65676B', // Meta's secondary color
    },
    error: {
      main: '#f44336',
    },
    background: {
      default: '#F0F2F5', // Meta's background color
    },
  },
  typography: {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
    fontSize: 14,
    button: {
      textTransform: 'none',
      fontWeight: 'bold', // Meta often uses bold buttons
    },
    h5: {
      fontSize: '1.25rem', // Adjust heading sizes as needed
      fontWeight: 'bold',
    },
    // You can add more typography styles here
  },
  shape: {
    borderRadius: 8, // Rounded corners like Meta
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8, // Ensure this matches the shape.borderRadius
          boxShadow: 'none', // Remove button shadow
          '&:hover': {
            boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)', // Subtle hover effect
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none', // This line will remove the shadow from all Cards
          borderRadius: 8, // Consistent rounded corners for cards
        },
      },
    },
    // ... Add other component overrides as needed
  },
  // ... Add more customizations here if necessary
});

export default theme;