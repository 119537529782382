// FitsContext.js

import React, { createContext, useState, useEffect } from 'react';
import { db } from '../utils/firebaseConfig';
import { collection, getDocs, doc, updateDoc, deleteDoc, getDoc, setDoc, increment } from 'firebase/firestore';
import { deleteImageFromStorage } from '../utils/dbUtilities';

export const FitsContext = createContext();

export const FitsProvider = ({ children }) => {
  const [submissions, setSubmissions] = useState([]);
  const [loadingSubmissions, setLoadingSubmissions] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSubmissions = async () => {
      setLoadingSubmissions(true);
      console.log('Fetching submissions from database.');
      try {
        const querySnapshot = await getDocs(collection(db, 'fitsBoardItems'));
        const allSubmissions = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log('Submissions fetched:', allSubmissions);
        setSubmissions(allSubmissions);
      } catch (error) {
        console.error('Error fetching submissions:', error);
        setError(error);
      } finally {
        setLoadingSubmissions(false);
      }
    };
    fetchSubmissions();
  }, []);

  const deleteSubmission = async (id) => {
    const submission = submissions.find(sub => sub.id === id);
    if (submission) {
      console.log(`Found submission to delete with ID: ${id} and URL: ${submission.imageUrl}`);
      try {
        await deleteDoc(doc(db, 'fitsBoardItems', id));
        if (submission.imageUrl) {
          await deleteImageFromStorage(submission.imageUrl);
        }
        const updatedSubmissions = submissions.filter(sub => sub.id !== id);
        setSubmissions(updatedSubmissions);
        console.log(`Submission with ID: ${id} deleted successfully.`);
      } catch (error) {
        console.error(`Error deleting submission: ${error}`);
        setError(error);
      }
    } else {
      console.log(`No submission found with ID: ${id} to delete.`);
    }
  };

  const updateSubmission = async (id, updatedData) => {
    console.log(`Attempting to update submission with ID: ${id}`, updatedData);
    try {
      await updateDoc(doc(db, 'fitsBoardItems', id), updatedData);
      const updatedSubmissions = submissions.map(sub => {
        if (sub.id === id) {
          return { ...sub, ...updatedData };
        }
        return sub;
      });
      setSubmissions(updatedSubmissions);
      console.log(`Submission with ID: ${id} updated successfully.`);
    } catch (error) {
      console.error(`Error updating submission: ${error}`);
      setError(error);
    }
  };

  const incrementSuggestionCount = async () => {
    const suggestionCountRef = doc(db, 'stats', 'suggestionCount');
    try {
      const suggestionCountDoc = await getDoc(suggestionCountRef);

      if (suggestionCountDoc.exists()) {
        await updateDoc(suggestionCountRef, {
          count: increment(1),
        });
        console.log('Suggestion count incremented.');
      } else {
        await setDoc(suggestionCountRef, {
          count: 1,
        });
        console.log('Suggestion count initialized and set to 1.');
      }
    } catch (error) {
      console.error("Error incrementing suggestion count:", error);
      setError(error);
    }
  };

  const incrementItemsSuggestedCount = async (count) => {
    const itemsSuggestedCountRef = doc(db, 'stats', 'itemsSuggestedCount');
    try {
      const itemsSuggestedCountDoc = await getDoc(itemsSuggestedCountRef);

      if (itemsSuggestedCountDoc.exists()) {
        await updateDoc(itemsSuggestedCountRef, {
          count: increment(count),
        });
        console.log(`Items suggested count incremented by ${count}.`);
      } else {
        await setDoc(itemsSuggestedCountRef, {
          count: count,
        });
        console.log(`Items suggested count initialized and set to ${count}.`);
      }
    } catch (error) {
      console.error("Error incrementing items suggested count:", error);
      setError(error);
    }
  };

  return (
    <FitsContext.Provider value={{ submissions, setSubmissions, deleteSubmission, updateSubmission, incrementSuggestionCount, incrementItemsSuggestedCount, loadingSubmissions, error }}>
      {children}
    </FitsContext.Provider>
  );
};

export default FitsContext;
