// LoginPage.js

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Typography, Container, Card, CardContent } from '@mui/material';
import './LoginPage.css';
import { auth, db } from '../utils/firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useAuth } from '../contexts/AuthContext';
import { getUserByUsername } from '../utils/dbUtilities';
import { useMessages } from '../contexts/MessagesContext';
import { doc, getDoc, setDoc } from 'firebase/firestore';

const LoginPage = () => {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [mathChallenge, setMathChallenge] = useState({});
  const [mathAnswer, setMathAnswer] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();
  const { setIsLoggedIn } = useAuth();
  const { fetchMessages } = useMessages();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls the page to the top when the component mounts
    generateMathChallenge();
  }, []);

  const generateMathChallenge = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setMathChallenge({ num1, num2 });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (parseInt(mathAnswer) !== mathChallenge.num1 + mathChallenge.num2) {
      setError('Incorrect answer to math problem.');
      generateMathChallenge();
      return;
    }

    let userEmail = identifier.toLowerCase();

    if (!identifier.includes('@')) {
      try {
        const localUser = await getUserByUsername(identifier.toLowerCase());
        userEmail = localUser ? localUser.email : null;
      } catch (dbError) {
        console.error("Error reading from database:", dbError);
        userEmail = null;
      }
    }

    if (userEmail) {
      try {
        const signInResponse = await signInWithEmailAndPassword(auth, userEmail, password);
        console.log("Firebase sign-in successful, user:", signInResponse.user);
        
        const firebaseUser = signInResponse.user;
        const userDocRef = doc(db, 'users', firebaseUser.uid);
        const userDoc = await getDoc(userDocRef);
        
        if (!userDoc.exists() || !userDoc.data().blockedUsers) {
          await setDoc(userDocRef, {
            ...userDoc.data(),
            blockedUsers: userDoc.data().blockedUsers || {} // Initialize blockedUsers field
          }, { merge: true }); // Use merge to update the document without overwriting existing data
          console.log(`LoginPage: User document updated for ${firebaseUser.uid}`); // Log update
        }
        
        setIsLoggedIn(true);
        navigate('/');
      } catch (err) {
        console.error('Error during sign-in:', err);
        setError('Incorrect Login. Try Again.');
      }
    } else {
      setError('Incorrect Login. Try Again.');
    }
  };

  return (
    <div className="login-page-container">
      <Container component="main" maxWidth="md">
        <Card className="card-container">
          <CardContent>
            <Typography variant="h4" className="login-header">Login</Typography>
            {error && <Typography color="error" className="error-message">{error}</Typography>}
            <form onSubmit={handleSubmit} className="login-form">
              <TextField
                type="text"
                label="Email or Username"
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
                className="login-input"
              />
              <TextField
                type="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                fullWidth
                margin="normal"
                className="login-input"
              />
              <div>
                Solve this to prove you're not a robot: {mathChallenge.num1} + {mathChallenge.num2} = ?
              </div>
              <TextField
                fullWidth
                variant="outlined"
                value={mathAnswer}
                onChange={(e) => setMathAnswer(e.target.value)}
                error={error !== null && error.includes('math problem')}
                helperText={error && error.includes('math problem') ? error : ''}
                sx={{ '& .MuiInputBase-input': { textAlign: 'center' } }}
                margin="normal"
                className="login-input"
              />
              <Button type="submit" variant="contained" color="primary" fullWidth className="login-button">Login</Button>
            </form>
            <div className="login-links">
              <Link to="/forgot-password" className="forgot-link">Forgot Password?</Link>
              <div className="signup-text">
                Don't have an account? <Link to="/signup">Sign Up</Link>
              </div>
            </div>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
};

export default LoginPage;
