// InfoPage.js

import React, { useEffect } from 'react';
import './InfoPage.css';

const InfoPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top when the component mounts
    }, []);

    return (
        <div className="info-container">
            <section className="welcome-section">
                <h1>Welcome to HyroFits!</h1>
                <p>At HyroFits, we make finding stylish clothes easy. Our App Generates Clothing Recommendations Based on Age, Gender, Weather, and Dress Code. </p>
                <p>Our mission is to make it easy for you to find the best clothes for yourself or others (eg gifts). </p>
                <p>Find Clothes For Yourself or Stylish Gifts with HyroFits!</p>
            </section>

            <section className="terms-of-use">
                <h2>Terms of Use</h2>
                <p>HyroFits, a service provided by Hyroglf, LLC, is designed to offer personalized outfit recommendations. By accessing or interacting with our servers, services, website, or mobile application ("App"), you agree to these Terms of Use ("TOU"). Your use of HyroFits signifies your acceptance of these TOU. Please note that HyroFits is a private platform owned by Hyroglf, LLC. If you represent a business using HyroFits, you confirm your authority to bind that business to these TOU.</p>
                
                <h3>Disclaimer of Responsibility</h3>
                <p>Hyroglf, LLC and HyroFits do not process transactions or handle any purchases. All suggested clothing item images and links are uploaded by Hyroglf. When clicked, the user is redirected to the external URL of the clothing retailer selling the clicked item. No transactions take place on HyroFits. HyroFits is free to use, but there is tracking and when certain items are purchased, HyroFits receives a percentage of the sale price via an affiliate program link between Hyroglf and the item retailer.</p>
                
                <h3>License</h3>
                <p>Hyroglf, LLC grants you a limited, revocable, non-exclusive, non-assignable license to use HyroFits in compliance with these TOU. Unauthorized use is not permitted. You are prohibited from misusing any content from HyroFits, except for content you personally create.</p>
                
                <h3>Usage Policy</h3>
                <p>You agree to use HyroFits responsibly and lawfully. You must respect all moderation efforts and the privacy and rights of other users.</p>
                
                <h3>Disclaimer & Liability</h3>
                <p>HyroFits, provided "AS IS" by Hyroglf, LLC, comes without warranties of any kind. Hyroglf, LLC is not liable for any damages or losses resulting from your use of HyroFits or from recommendations provided by its use.</p>
                
                <h3>Claims & Indemnity</h3>
                <p>Any disputes related to these TOU will be governed by the laws of the jurisdiction where Hyroglf, LLC is located. You agree to indemnify Hyroglf, LLC against any claims related to your use of HyroFits.</p>
                
                <h3>Miscellaneous</h3>
                <p>These TOU constitute the entire agreement between you and Hyroglf, LLC regarding HyroFits. Changes to these TOU will be effective upon posting, and your continued use of HyroFits signifies acceptance of these changes.</p>
            </section>
        </div>
    );
}

export default InfoPage;
