// Sidebar.js

import React, { useContext, useEffect } from 'react';
import { FaHouseUser, FaEnvelope, FaSignOutAlt, FaUserPlus, FaInfoCircle, FaCog, FaBars, FaTshirt } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import CloseButton from './CloseButton';
import { useAuth } from '../contexts/AuthContext';
import ActiveItemContext from '../contexts/ActiveItemContext';
import { saveDataToDB } from '../utils/dbUtilities';

const Sidebar = ({ isVisible, setIsSidebarVisible }) => {
  const navigate = useNavigate();
  const { currentUser: authUser, logout } = useAuth();
  const { activeItem, setActiveItem } = useContext(ActiveItemContext);

  useEffect(() => {
    if (isVisible) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }
  }, [isVisible]);

  // Check for admin early and set a variable
  const isAdmin = authUser && authUser.role === 'admin';

  // If not logged in or not an admin, do not render the sidebar
  if (!isAdmin) {
    return null;
  }

  const handleLogoutClick = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error("Failed to log out:", error);
    }
  };

  const baseMenuItems = [
    { id: 'Home', icon: <FaHouseUser className="sidebar-icon" />, link: '/' },
    { id: 'Info', icon: <FaInfoCircle className="sidebar-icon" />, link: '/info' },
    { id: 'Contact', icon: <FaEnvelope className="sidebar-icon" />, link: '/contact' },
    { id: 'Fits Board', icon: <FaTshirt className="sidebar-icon" />, link: '/fits-board' }  // Added this line
  ];

  const loggedInMenuItems = [
    { id: 'Logout', icon: <FaSignOutAlt className="sidebar-icon" />, onClick: handleLogoutClick }
  ];

  const adminMenuItems = [
    { id: 'Admin Dashboard', icon: <FaCog className="sidebar-icon" />, link: '/admin-dashboard' }
  ];

  // Combine menu items based on user's login status and role
  let menuItems = [...baseMenuItems, ...loggedInMenuItems, ...adminMenuItems];

  const handleItemClick = async (item) => {
    if (window.innerWidth <= 768) {
      setIsSidebarVisible(false);
      document.body.classList.remove('sidebar-open');
    }

    setActiveItem(item.id);

    if (item.link) {
      navigate(item.link);
    } else if (item.onClick) {
      item.onClick();
    }

    if (authUser) {
      try {
        await saveDataToDB('userSettings', { activeItem: item.id }, authUser.uid);
      } catch (error) {
        console.error('Error saving active item:', error);
      }
    }
  };

  const SidebarItem = ({ item }) => {
    return (
      <li className={`sidebar-item ${activeItem === item.id ? 'active' : ''}`}>
        <Link to={item.link || '#'} className="sidebar-link" onClick={(e) => {
            e.preventDefault(); // Prevent default action to ensure link behavior is controlled
            handleItemClick(item);
          }}>
          <div className="sidebar-link-content">
            {item.icon}
            <span className="sidebar-text">{item.id}</span>
          </div>
        </Link>
      </li>
    );
  };

  return (
    <div className={`sidebar ${isVisible ? 'open' : ''}`}>
      <div className="sidebar-close-button">
        <CloseButton onClick={() => {
          if (window.innerWidth <= 768) {
            setIsSidebarVisible(false);
            document.body.classList.remove('sidebar-open');
          }
        }} />
      </div>
      <div className="sidebar-hamburger">
        <FaBars onClick={() => setIsSidebarVisible(!isVisible)} className="hamburger-icon" />
      </div>
      <div className="profile-section">
        <Link to="/" className="profile-link" style={{ textDecoration: 'none', color: 'inherit' }} onClick={(e) => {
            e.preventDefault(); // Prevent the default link action
            if (window.innerWidth <= 768) {
              setIsSidebarVisible(false);
            }
            navigate('/');
        }}>
          <h3 className="profile-name">{authUser ? (authUser.username || authUser.displayName || authUser.email.split('@')[0]) : 'HyroFits'}</h3>
        </Link>
      </div>
      <nav>
        <ul className="sidebar-menu">
          {menuItems.map(item => <SidebarItem key={item.id} item={item} />)}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;

