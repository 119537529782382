// SuggestionGenerator.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, TextField, Button, MenuItem, Container } from '@mui/material';
import './SuggestionGenerator.css';
import { useNavigate } from 'react-router-dom';

const SuggestionGenerator = () => {
    const [input, setInput] = useState(sessionStorage.getItem('input') || '');
    const [category, setCategory] = useState(sessionStorage.getItem('category') || '');
    const [timeframe, setTimeframe] = useState(sessionStorage.getItem('timeframe') || '');
    const [suggestions, setSuggestions] = useState(JSON.parse(sessionStorage.getItem('suggestions')) || []);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        sessionStorage.setItem('input', input);
        sessionStorage.setItem('category', category);
        sessionStorage.setItem('timeframe', timeframe);
        sessionStorage.setItem('suggestions', JSON.stringify(suggestions));
    }, [input, category, timeframe, suggestions]);

    const handleGenerateSuggestions = async (event) => {
        event.preventDefault();
        setError('');

        if (!input && !category && !timeframe) {
            setError('Please enter or select at least one field to generate suggestions.');
            return;
        }

        const parts = [];
        if (input) parts.push(`related to "${input}"`);
        if (category) parts.push(`in the category "${category}"`);
        if (timeframe) parts.push(`from "${timeframe}"`);

        let promptPart = parts.join(' and ');
        if (promptPart) promptPart = ` for unique items ${promptPart}`;

        const prompt = `Please provide four suggestions${promptPart} that are not widely available for sale, in bullet point format. Each suggestion should be max 10 word sentences.`;

        try {
            const response = await axios.post(
                'https://api.openai.com/v1/completions',
                {
                    model: "gpt-3.5-turbo-instruct",
                    prompt: prompt,
                    max_tokens: 200,
                    temperature: 0.7,
                    n: 1,
                },
                {
                    headers: {
                        'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_KEY}`
                    }
                }
            );
            const suggestionsText = response.data.choices[0].text.trim();
            const suggestionsList = suggestionsText.split('\n')
                                    .filter(item => item)
                                    .map(item => item.replace(/^\s*(\d+\.\s*|\-\s*|\s*)/, ''))
                                    .map(suggestion => suggestion.replace(/\.(\s*\(Images\))?$/, '')); // Remove period at the end of each suggestion
            setSuggestions(suggestionsList);
        } catch (error) {
            console.error('Error generating suggestions:', error);
            setError('Error generating suggestions. Please try again later.');
            setSuggestions([]);
        }        
    };

    const handleClearSuggestions = () => {
        setSuggestions([]);
        setInput('');
        setCategory('');
        setTimeframe('');
    };

    const shouldShowClearButton = input || category || timeframe;

    return (
        <div className="suggestion-form-container">
            <Container component="main" maxWidth="sm" className="suggestion-form">
                <Card>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            Generate Post Suggestions
                        </Typography>
                        <Typography gutterBottom variant="subtitle1" component="div">
                            Fill in at least one (1).
                        </Typography>
                        <form onSubmit={handleGenerateSuggestions}>
                            <TextField
                                label="Keyword(s)"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                            />
                            <TextField
                                label="Category"
                                variant="outlined"
                                select
                                fullWidth
                                margin="normal"
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                            >
                                {[
                                    'Clothing, Accessories & Fashion',
                                    'Electronics',
                                    'Music',
                                    'Entertainment',
                                    'Household Items',
                                    'Children',
                                    'Outdoors',
                                    'Collectibles',
                                    'Art',
                                    'Books',
                                    'Sports',
                                    'Jewelry & Watches',
                                    'Health & Beauty',
                                    'Pets',
                                    'Business & Commerce'
                                ].map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                label="Timeframe"
                                variant="outlined"
                                select
                                fullWidth
                                margin="normal"
                                value={timeframe}
                                onChange={(e) => setTimeframe(e.target.value)}
                            >
                                {['Current', '2000s', '1990s', '1980s', 'Vintage'].map(option => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <Button 
                                type="submit" 
                                variant="contained" 
                                color="primary" 
                                className="generate-button"
                                disabled={!input && !category && !timeframe}
                            >
                                {suggestions.length > 0 ? 'Refresh' : 'Generate Suggestions'}
                            </Button>
                            {shouldShowClearButton && (
                                <Button 
                                    type="button" 
                                    variant="contained" 
                                    color="secondary" 
                                    onClick={handleClearSuggestions} 
                                    style={{ marginLeft: '10px' }}
                                >
                                    Clear
                                </Button>
                            )}
                        </form>
                        {error && <Typography color="error">{error}</Typography>}
                        {suggestions.length > 0 && (
                            <div className="suggestions-list">
                                {suggestions.map((suggestion, index) => (
                                    <div key={index} style={{ marginBottom: '10px' }}>
                                        <Typography
                                            variant="body1"
                                            style={{ display: 'block' }}
                                            onClick={() => navigate('/post', { state: { suggestionText: suggestion } })}
                                        >
                                            <a
                                                href="#"
                                                onClick={(e) => { e.preventDefault(); navigate('/post', { state: { suggestionText: suggestion } }); }}
                                                className="suggestion-link"
                                            >
                                                {suggestion}
                                            </a>
                                            <span> (</span><a
                                                href={`https://www.google.com/search?tbm=isch&q=${encodeURIComponent(suggestion)}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="image-link"
                                            >
                                                Images
                                            </a><span>)</span>
                                        </Typography>
                                    </div>
                                ))}
                            </div>
                        )}
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
};

export default SuggestionGenerator;
